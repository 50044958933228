import { Box, Flex, Image, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import logoWhite from "../assets/images/logoWhite.svg";
import s1 from "../assets/images/s1.png";
import s2 from "../assets/images/s2.png";
import s3 from "../assets/images/s3.png";
import s4 from "../assets/images/s4.png";
import location from "../assets/images/location.svg";

export default function Footer({ lang }) {
    const history = useHistory();

    const menu = [s1, s2, s3, s4];
    const menu2 = [
        lang === "fr" ? "À propos de nous" : "About us",
        "Contact",
        "Privacy policy",
        "AML Policy",
        "Terms and Conditions",
    ];
    return (
        <Flex w="100%" justify="center" bg="#4E0DD9" color="#fff">
            <Flex className="container" px={["5", "3%"]}>
                <Flex w="100%" py={["40px", "80px"]} direction="column">
                    <Flex w="100%" justify="space-between" align="center">
                        <Flex
                            cursor="pointer"
                            onClick={() => {
                                return window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <Image w={["74px", "101.33px"]} src={logoWhite} />
                        </Flex>
                        <Flex gap="6" align="end" direction="column">
                            <Flex w={["70%", 'auto']} gap="1" fontSize={["13px", "16px"]} align="end" justify="end">
                                <Image cursor="pointer" h={["17px", "19.96px"]} src={location}  alt="location"/>
                                No. 2 Opefia Close, off Mobil Estate Road, Lekki-Epe, Lagos.
                            </Flex>

                            <Flex>
                                {menu.map((item, index) => (
                                    <Image
                                        cursor="pointer"
                                        h="19.96px"
                                        src={item}
                                        key={index}
                                        mr={[index !== 3 && "4", index !== 3 && "6"]}
                                    />
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex w="100%" bg="#7F48F4" h="1px" my={["24px", "60px"]}></Flex>
                    <Flex w="100%" justify="space-between" align="center">
                        <Text w={["400px", "auto"]} mr={["4", "0"]} fontSize={["13px", "15px"]}>
                            {lang === "fr"
                                ? "Portefeuille Zine @ 2024. Tous droits réservés."
                                : "Zine Wallet @ 2024. All rights reserved."}
                        </Text>
                        <Flex gap="5" align="end" direction="column">
                            <Wrap
                                w={["auto", "70%", "80%"]}
                                align="end"
                                justify="end"
                                fontSize={["13px", "16px"]}
                                spacing={["10px", "20px"]}
                            >
                                {menu2.map((item, index) => (
                                    <WrapItem
                                        cursor="pointer"
                                        key={index}
                                        transition="300ms ease-in-out"
                                        _hover={{ fontWeight: "600" }}
                                        onClick={() => {
                                            if (index === 0) {
                                                history.push("/");
                                                const element = document.getElementById("aboutPage");
                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 0 : 0,
                                                    behavior: "smooth",
                                                });
                                            } else if (index === 1) {
                                                history.push("/");
                                                const element = document.getElementById("contactPage");
                                                console.log(element);

                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 50 : 0,
                                                    behavior: "smooth",
                                                });
                                            } else if (index === 2) {
                                                history.push("/privacy-policy");
                                            } else if (index === 3) {
                                                history.push("/aml-policy");
                                            } else if (index === 4) {
                                                history.push("/terms-and-conditions");
                                            }
                                        }}
                                    >
                                        {item}
                                    </WrapItem>
                                ))}
                            </Wrap>
                            <Text align="end" as="b" fontSize={["15px", "16px"]}>
                                A Product of Zine Fintech Limited
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
