import { Flex, Image, Spinner, Text } from "@chakra-ui/react";
import bg1 from "../assets/images/bg1.png";
import bg2 from "../assets/images/bg1Civ.png";
import ap from "../assets/images/ap.svg";
import gp from "../assets/images/gp.svg";
import hs1 from "../assets/images/hs1.png";
import hs1french from "../assets/images/hs1french.png";
import { useEffect, useState, useRef } from "react";
import phone1 from "../assets/images/phone1.svg";
import phone1Mob from "../assets/images/phone1Mob.svg";
import sec1 from "../assets/images/sec1.png";
import sec2 from "../assets/images/sec2.png";
import sec3 from "../assets/images/sec3.png";
import sec4 from "../assets/images/sec4.png";
import sec5 from "../assets/images/sec5.svg";
import phone2 from "../assets/images/phone2.svg";
import hs2 from "../assets/images/hs2.png";
import flash from "../assets/images/flash.png";
import hs3 from "../assets/images/hs3.png";
import hs4 from "../assets/images/hs4.svg";
import { Accordion, AccordionItem as NewItem } from "@szhsin/react-accordion";
import plus from "../assets/images/plus.svg";
import minus from "../assets/images/minus.svg";
import { CoinMarketCap } from "../util/request";
import InputHolder from "../components/inputHolder";
import her1 from "../assets/images/her1.png";
import her2 from "../assets/images/her2.png";
import her3 from "../assets/images/her3.png";
import btc from "../assets/images/btc.png";
import eth from "../assets/images/eth.png";
import bnb from "../assets/images/bnb.png";
import usd from "../assets/images/usd.png";
import sol from "../assets/images/sol.png";
import TradingViewWidget from "../components/td";
import TradingViewWidget2 from "../components/td2";
import NavBar from "../components/navBar";
import { AuthConnect } from "../util/request.js";
import Toast from "../components/notify";

export default function Landing() {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [cArr, setCArr] = useState([]);
    const [coin, setCoin] = useState("BTC");
    const [coinIni, setCoinIni] = useState("");

    const ref1 = useRef("");
    const ref2 = useRef("");
    const ref3 = useRef("");
    const ref4 = useRef("");
    const ref5 = useRef("");

    async function fetchTopCryptoNoLoad() {
        const res = await CoinMarketCap("get", "listings?pairs=BTC/USDT,USDT/USD,ETH/USDT,SOL/USDT,BNB/USDT");
        setCArr(res.data);
        setTimeout(function () {
            fetchTopCryptoNoLoad();
        }, 3000);
    }

    async function fetchTopCrypto() {
        setLoading(true);
        const res = await CoinMarketCap("get", "listings?pairs=BTC/USDT,USDT/USD,ETH/USDT,SOL/USDT,BNB/USDT");
        setCArr(res?.data);
        setLoading(false);
        setTimeout(function () {
            fetchTopCryptoNoLoad();
        }, 3000);
    }

    useEffect(() => {
        fetchTopCrypto();
    }, []);

    async function contactFunc(e) {
        e.preventDefault();
        setLoading2(true);
        var a = ref1.current.value;
        var b = ref2.current.value;
        var c = ref3.current.value;
        var d = ref4.current.value;
        var e = ref5.current.value;
        const res = await AuthConnect("post", "", {
            firstName: a,
            lastName: b,
            phoneNumber: c,
            emailAddress: d,
            message: e,
        });
        if (res) {
            Toast(res?.message?.enquiry_submitted, "success");
            ref1.current.value = "";
            ref2.current.value = "";
            ref3.current.value = "";
            ref4.current.value = "";
            ref5.current.value = "";
        }
        setLoading2(false);
    }

    const [iniColor, setIniColor] = useState("#fff");
    const [lang, setLang] = useState("");
    const mapTimezoneToCountry = {
        "Europe/Andorra": "Andorra",
        "Asia/Dubai": "United Arab Emirates",
        "Asia/Kabul": "Afghanistan",
        "Europe/Tirane": "Albania",
        "Asia/Yerevan": "Armenia",
        "Antarctica/Casey": "Antarctica",
        "Antarctica/Davis": "Antarctica",
        "Antarctica/Mawson": "Antarctica",
        "Antarctica/Palmer": "Antarctica",
        "Antarctica/Rothera": "Antarctica",
        "Antarctica/Troll": "Antarctica",
        "Antarctica/Vostok": "Antarctica",
        "America/Argentina/Buenos_Aires": "Argentina",
        "America/Argentina/Cordoba": "Argentina",
        "America/Argentina/Salta": "Argentina",
        "America/Argentina/Jujuy": "Argentina",
        "America/Argentina/Tucuman": "Argentina",
        "America/Argentina/Catamarca": "Argentina",
        "America/Argentina/La_Rioja": "Argentina",
        "America/Argentina/San_Juan": "Argentina",
        "America/Argentina/Mendoza": "Argentina",
        "America/Argentina/San_Luis": "Argentina",
        "America/Argentina/Rio_Gallegos": "Argentina",
        "America/Argentina/Ushuaia": "Argentina",
        "Pacific/Pago_Pago": "Samoa (American)",
        "Europe/Vienna": "Austria",
        "Australia/Lord_Howe": "Australia",
        "Antarctica/Macquarie": "Australia",
        "Australia/Hobart": "Australia",
        "Australia/Melbourne": "Australia",
        "Australia/Sydney": "Australia",
        "Australia/Broken_Hill": "Australia",
        "Australia/Brisbane": "Australia",
        "Australia/Lindeman": "Australia",
        "Australia/Adelaide": "Australia",
        "Australia/Darwin": "Australia",
        "Australia/Perth": "Australia",
        "Australia/Eucla": "Australia",
        "Asia/Baku": "Azerbaijan",
        "America/Barbados": "Barbados",
        "Asia/Dhaka": "Bangladesh",
        "Europe/Brussels": "Belgium",
        "Europe/Sofia": "Bulgaria",
        "Atlantic/Bermuda": "Bermuda",
        "Asia/Brunei": "Brunei",
        "America/La_Paz": "Bolivia",
        "America/Noronha": "Brazil",
        "America/Belem": "Brazil",
        "America/Fortaleza": "Brazil",
        "America/Recife": "Brazil",
        "America/Araguaina": "Brazil",
        "America/Maceio": "Brazil",
        "America/Bahia": "Brazil",
        "America/Sao_Paulo": "Brazil",
        "America/Campo_Grande": "Brazil",
        "America/Cuiaba": "Brazil",
        "America/Santarem": "Brazil",
        "America/Porto_Velho": "Brazil",
        "America/Boa_Vista": "Brazil",
        "America/Manaus": "Brazil",
        "America/Eirunepe": "Brazil",
        "America/Rio_Branco": "Brazil",
        "Asia/Thimphu": "Bhutan",
        "Europe/Minsk": "Belarus",
        "America/Belize": "Belize",
        "America/St_Johns": "Canada",
        "America/Halifax": "Canada",
        "America/Glace_Bay": "Canada",
        "America/Moncton": "Canada",
        "America/Goose_Bay": "Canada",
        "America/Toronto": "Canada",
        "America/Nipigon": "Canada",
        "America/Thunder_Bay": "Canada",
        "America/Iqaluit": "Canada",
        "America/Pangnirtung": "Canada",
        "America/Winnipeg": "Canada",
        "America/Rainy_River": "Canada",
        "America/Resolute": "Canada",
        "America/Rankin_Inlet": "Canada",
        "America/Regina": "Canada",
        "America/Swift_Current": "Canada",
        "America/Edmonton": "Canada",
        "America/Cambridge_Bay": "Canada",
        "America/Yellowknife": "Canada",
        "America/Inuvik": "Canada",
        "America/Dawson_Creek": "Canada",
        "America/Fort_Nelson": "Canada",
        "America/Whitehorse": "Canada",
        "America/Dawson": "Canada",
        "America/Vancouver": "Canada",
        "Indian/Cocos": "Cocos (Keeling) Islands",
        "Europe/Zurich": "Switzerland",
        "Africa/Abidjan": "Cote d'Ivoire",
        "Pacific/Rarotonga": "Cook Islands",
        "America/Santiago": "Chile",
        "America/Punta_Arenas": "Chile",
        "Pacific/Easter": "Chile",
        "Asia/Shanghai": "China",
        "Asia/Urumqi": "China",
        "America/Bogota": "Colombia",
        "America/Costa_Rica": "Costa Rica",
        "America/Havana": "Cuba",
        "Atlantic/Cape_Verde": "Cape Verde",
        "Indian/Christmas": "Christmas Island",
        "Asia/Nicosia": "Cyprus",
        "Asia/Famagusta": "Cyprus",
        "Europe/Prague": "Czech Republic",
        "Europe/Berlin": "Germany",
        "Europe/Copenhagen": "Denmark",
        "America/Santo_Domingo": "Dominican Republic",
        "Africa/Algiers": "Algeria",
        "America/Guayaquil": "Ecuador",
        "Pacific/Galapagos": "Ecuador",
        "Europe/Tallinn": "Estonia",
        "Africa/Cairo": "Egypt",
        "Africa/El_Aaiun": "Western Sahara",
        "Europe/Madrid": "Spain",
        "Africa/Ceuta": "Spain",
        "Atlantic/Canary": "Spain",
        "Europe/Helsinki": "Finland",
        "Pacific/Fiji": "Fiji",
        "Atlantic/Stanley": "Falkland Islands",
        "Pacific/Chuuk": "Micronesia",
        "Pacific/Pohnpei": "Micronesia",
        "Pacific/Kosrae": "Micronesia",
        "Atlantic/Faroe": "Faroe Islands",
        "Europe/Paris": "France",
        "Europe/London": "Britain (UK)",
        "Asia/Tbilisi": "Georgia",
        "America/Cayenne": "French Guiana",
        "Europe/Gibraltar": "Gibraltar",
        "America/Nuuk": "Greenland",
        "America/Danmarkshavn": "Greenland",
        "America/Scoresbysund": "Greenland",
        "America/Thule": "Greenland",
        "Europe/Athens": "Greece",
        "Atlantic/South_Georgia": "South Georgia & the South Sandwich Islands",
        "America/Guatemala": "Guatemala",
        "Pacific/Guam": "Guam",
        "Africa/Bissau": "Guinea-Bissau",
        "America/Guyana": "Guyana",
        "Asia/Hong_Kong": "Hong Kong",
        "America/Tegucigalpa": "Honduras",
        "America/Port-au-Prince": "Haiti",
        "Europe/Budapest": "Hungary",
        "Asia/Jakarta": "Indonesia",
        "Asia/Pontianak": "Indonesia",
        "Asia/Makassar": "Indonesia",
        "Asia/Jayapura": "Indonesia",
        "Europe/Dublin": "Ireland",
        "Asia/Jerusalem": "Israel",
        "Asia/Kolkata": "India",
        "Asia/Calcutta": "India",
        "Indian/Chagos": "British Indian Ocean Territory",
        "Asia/Baghdad": "Iraq",
        "Asia/Tehran": "Iran",
        "Atlantic/Reykjavik": "Iceland",
        "Europe/Rome": "Italy",
        "America/Jamaica": "Jamaica",
        "Asia/Amman": "Jordan",
        "Asia/Tokyo": "Japan",
        "Africa/Nairobi": "Kenya",
        "Asia/Bishkek": "Kyrgyzstan",
        "Pacific/Tarawa": "Kiribati",
        "Pacific/Kanton": "Kiribati",
        "Pacific/Kiritimati": "Kiribati",
        "Asia/Pyongyang": "Korea (North)",
        "Asia/Seoul": "Korea (South)",
        "Asia/Almaty": "Kazakhstan",
        "Asia/Qyzylorda": "Kazakhstan",
        "Asia/Qostanay": "Kazakhstan",
        "Asia/Aqtobe": "Kazakhstan",
        "Asia/Aqtau": "Kazakhstan",
        "Asia/Atyrau": "Kazakhstan",
        "Asia/Oral": "Kazakhstan",
        "Asia/Beirut": "Lebanon",
        "Asia/Colombo": "Sri Lanka",
        "Africa/Monrovia": "Liberia",
        "Europe/Vilnius": "Lithuania",
        "Europe/Luxembourg": "Luxembourg",
        "Europe/Riga": "Latvia",
        "Africa/Tripoli": "Libya",
        "Africa/Casablanca": "Morocco",
        "Europe/Monaco": "Monaco",
        "Europe/Chisinau": "Moldova",
        "Pacific/Majuro": "Marshall Islands",
        "Pacific/Kwajalein": "Marshall Islands",
        "Asia/Yangon": "Myanmar (Burma)",
        "Asia/Ulaanbaatar": "Mongolia",
        "Asia/Hovd": "Mongolia",
        "Asia/Choibalsan": "Mongolia",
        "Asia/Macau": "Macau",
        "America/Martinique": "Martinique",
        "Europe/Malta": "Malta",
        "Indian/Mauritius": "Mauritius",
        "Indian/Maldives": "Maldives",
        "America/Mexico_City": "Mexico",
        "America/Cancun": "Mexico",
        "America/Merida": "Mexico",
        "America/Monterrey": "Mexico",
        "America/Matamoros": "Mexico",
        "America/Mazatlan": "Mexico",
        "America/Chihuahua": "Mexico",
        "America/Ojinaga": "Mexico",
        "America/Hermosillo": "Mexico",
        "America/Tijuana": "Mexico",
        "America/Bahia_Banderas": "Mexico",
        "Asia/Kuala_Lumpur": "Malaysia",
        "Asia/Kuching": "Malaysia",
        "Africa/Maputo": "Mozambique",
        "Africa/Windhoek": "Namibia",
        "Pacific/Noumea": "New Caledonia",
        "Pacific/Norfolk": "Norfolk Island",
        "Africa/Lagos": "Nigeria",
        "America/Managua": "Nicaragua",
        "Europe/Amsterdam": "Netherlands",
        "Europe/Oslo": "Norway",
        "Asia/Kathmandu": "Nepal",
        "Pacific/Nauru": "Nauru",
        "Pacific/Niue": "Niue",
        "Pacific/Auckland": "New Zealand",
        "Pacific/Chatham": "New Zealand",
        "America/Panama": "Panama",
        "America/Lima": "Peru",
        "Pacific/Tahiti": "French Polynesia",
        "Pacific/Marquesas": "French Polynesia",
        "Pacific/Gambier": "French Polynesia",
        "Pacific/Port_Moresby": "Papua New Guinea",
        "Pacific/Bougainville": "Papua New Guinea",
        "Asia/Manila": "Philippines",
        "Asia/Karachi": "Pakistan",
        "Europe/Warsaw": "Poland",
        "America/Miquelon": "St Pierre & Miquelon",
        "Pacific/Pitcairn": "Pitcairn",
        "America/Puerto_Rico": "Puerto Rico",
        "Asia/Gaza": "Palestine",
        "Asia/Hebron": "Palestine",
        "Europe/Lisbon": "Portugal",
        "Atlantic/Madeira": "Portugal",
        "Atlantic/Azores": "Portugal",
        "Pacific/Palau": "Palau",
        "America/Asuncion": "Paraguay",
        "Asia/Qatar": "Qatar",
        "Indian/Reunion": "Réunion",
        "Europe/Bucharest": "Romania",
        "Europe/Belgrade": "Serbia",
        "Europe/Kaliningrad": "Russia",
        "Europe/Moscow": "Russia",
        "Europe/Simferopol": "Russia",
        "Europe/Kirov": "Russia",
        "Europe/Volgograd": "Russia",
        "Europe/Astrakhan": "Russia",
        "Europe/Saratov": "Russia",
        "Europe/Ulyanovsk": "Russia",
        "Europe/Samara": "Russia",
        "Asia/Yekaterinburg": "Russia",
        "Asia/Omsk": "Russia",
        "Asia/Novosibirsk": "Russia",
        "Asia/Barnaul": "Russia",
        "Asia/Tomsk": "Russia",
        "Asia/Novokuznetsk": "Russia",
        "Asia/Krasnoyarsk": "Russia",
        "Asia/Irkutsk": "Russia",
        "Asia/Chita": "Russia",
        "Asia/Yakutsk": "Russia",
        "Asia/Khandyga": "Russia",
        "Asia/Vladivostok": "Russia",
        "Asia/Ust-Nera": "Russia",
        "Asia/Magadan": "Russia",
        "Asia/Sakhalin": "Russia",
        "Asia/Srednekolymsk": "Russia",
        "Asia/Kamchatka": "Russia",
        "Asia/Anadyr": "Russia",
        "Asia/Riyadh": "Saudi Arabia",
        "Pacific/Guadalcanal": "Solomon Islands",
        "Indian/Mahe": "Seychelles",
        "Africa/Khartoum": "Sudan",
        "Europe/Stockholm": "Sweden",
        "Asia/Singapore": "Singapore",
        "America/Paramaribo": "Suriname",
        "Africa/Juba": "South Sudan",
        "Africa/Sao_Tome": "Sao Tome & Principe",
        "America/El_Salvador": "El Salvador",
        "Asia/Damascus": "Syria",
        "America/Grand_Turk": "Turks & Caicos Is",
        "Africa/Ndjamena": "Chad",
        "Indian/Kerguelen": "French Southern & Antarctic Lands",
        "Asia/Bangkok": "Thailand",
        "Asia/Dushanbe": "Tajikistan",
        "Pacific/Fakaofo": "Tokelau",
        "Asia/Dili": "East Timor",
        "Asia/Ashgabat": "Turkmenistan",
        "Africa/Tunis": "Tunisia",
        "Pacific/Tongatapu": "Tonga",
        "Europe/Istanbul": "Turkey",
        "Pacific/Funafuti": "Tuvalu",
        "Asia/Taipei": "Taiwan",
        "Europe/Kiev": "Ukraine",
        "Europe/Uzhgorod": "Ukraine",
        "Europe/Zaporozhye": "Ukraine",
        "Pacific/Wake": "US minor outlying islands",
        "America/New_York": "United States",
        "America/Detroit": "United States",
        "America/Kentucky/Louisville": "United States",
        "America/Kentucky/Monticello": "United States",
        "America/Indiana/Indianapolis": "United States",
        "America/Indiana/Vincennes": "United States",
        "America/Indiana/Winamac": "United States",
        "America/Indiana/Marengo": "United States",
        "America/Indiana/Petersburg": "United States",
        "America/Indiana/Vevay": "United States",
        "America/Chicago": "United States",
        "America/Indiana/Tell_City": "United States",
        "America/Indiana/Knox": "United States",
        "America/Menominee": "United States",
        "America/North_Dakota/Center": "United States",
        "America/North_Dakota/New_Salem": "United States",
        "America/North_Dakota/Beulah": "United States",
        "America/Denver": "United States",
        "America/Boise": "United States",
        "America/Phoenix": "United States",
        "America/Los_Angeles": "United States",
        "America/Anchorage": "United States",
        "America/Juneau": "United States",
        "America/Sitka": "United States",
        "America/Metlakatla": "United States",
        "America/Yakutat": "United States",
        "America/Nome": "United States",
        "America/Adak": "United States",
        "Pacific/Honolulu": "United States",
        "America/Montevideo": "Uruguay",
        "Asia/Samarkand": "Uzbekistan",
        "Asia/Tashkent": "Uzbekistan",
        "America/Caracas": "Venezuela",
        "Asia/Ho_Chi_Minh": "Vietnam",
        "Pacific/Efate": "Vanuatu",
        "Pacific/Wallis": "Wallis & Futuna",
        "Pacific/Apia": "Samoa (western)",
        "Africa/Johannesburg": "South Africa",
        "America/Antigua": "Antigua & Barbuda",
        "America/Anguilla": "Anguilla",
        "Africa/Luanda": "Angola",
        "Antarctica/McMurdo": "Antarctica",
        "Antarctica/DumontDUrville": "Antarctica",
        "Antarctica/Syowa": "Antarctica",
        "America/Aruba": "Aruba",
        "Europe/Mariehamn": "Åland Islands",
        "Europe/Sarajevo": "Bosnia & Herzegovina",
        "Africa/Ouagadougou": "Burkina Faso",
        "Asia/Bahrain": "Bahrain",
        "Africa/Bujumbura": "Burundi",
        "Africa/Porto-Novo": "Benin",
        "America/St_Barthelemy": "St Barthelemy",
        "America/Kralendijk": "Caribbean NL",
        "America/Nassau": "Bahamas",
        "Africa/Gaborone": "Botswana",
        "America/Blanc-Sablon": "Canada",
        "America/Atikokan": "Canada",
        "America/Creston": "Canada",
        "Africa/Kinshasa": "Congo (Dem. Rep.)",
        "Africa/Lubumbashi": "Congo (Dem. Rep.)",
        "Africa/Bangui": "Central African Rep.",
        "Africa/Brazzaville": "Congo (Rep.)",
        "Africa/Douala": "Cameroon",
        "America/Curacao": "Curaçao",
        "Europe/Busingen": "Germany",
        "Africa/Djibouti": "Djibouti",
        "America/Dominica": "Dominica",
        "Africa/Asmara": "Eritrea",
        "Africa/Addis_Ababa": "Ethiopia",
        "Africa/Libreville": "Gabon",
        "America/Grenada": "Grenada",
        "Europe/Guernsey": "Guernsey",
        "Africa/Accra": "Ghana",
        "Africa/Banjul": "Gambia",
        "Africa/Conakry": "Guinea",
        "America/Guadeloupe": "Guadeloupe",
        "Africa/Malabo": "Equatorial Guinea",
        "Europe/Zagreb": "Croatia",
        "Europe/Isle_of_Man": "Isle of Man",
        "Europe/Jersey": "Jersey",
        "Asia/Phnom_Penh": "Cambodia",
        "Indian/Comoro": "Comoros",
        "America/St_Kitts": "St Kitts & Nevis",
        "Asia/Kuwait": "Kuwait",
        "America/Cayman": "Cayman Islands",
        "Asia/Vientiane": "Laos",
        "America/St_Lucia": "St Lucia",
        "Europe/Vaduz": "Liechtenstein",
        "Africa/Maseru": "Lesotho",
        "Europe/Podgorica": "Montenegro",
        "America/Marigot": "St Martin (French)",
        "Indian/Antananarivo": "Madagascar",
        "Europe/Skopje": "North Macedonia",
        "Africa/Bamako": "Mali",
        "Pacific/Saipan": "Northern Mariana Islands",
        "Africa/Nouakchott": "Mauritania",
        "America/Montserrat": "Montserrat",
        "Africa/Blantyre": "Malawi",
        "Africa/Niamey": "Niger",
        "Asia/Muscat": "Oman",
        "Africa/Kigali": "Rwanda",
        "Atlantic/St_Helena": "St Helena",
        "Europe/Ljubljana": "Slovenia",
        "Arctic/Longyearbyen": "Svalbard & Jan Mayen",
        "Europe/Bratislava": "Slovakia",
        "Africa/Freetown": "Sierra Leone",
        "Europe/San_Marino": "San Marino",
        "Africa/Dakar": "Senegal",
        "Africa/Mogadishu": "Somalia",
        "America/Lower_Princes": "St Maarten (Dutch)",
        "Africa/Mbabane": "Eswatini (Swaziland)",
        "Africa/Lome": "Togo",
        "America/Port_of_Spain": "Trinidad & Tobago",
        "Africa/Dar_es_Salaam": "Tanzania",
        "Africa/Kampala": "Uganda",
        "Pacific/Midway": "US minor outlying islands",
        "Europe/Vatican": "Vatican City",
        "America/St_Vincent": "St Vincent",
        "America/Tortola": "Virgin Islands (UK)",
        "America/St_Thomas": "Virgin Islands (US)",
        "Asia/Aden": "Yemen",
        "Indian/Mayotte": "Mayotte",
        "Africa/Lusaka": "Zambia",
        "Africa/Harare": "Zimbabwe",
    };
    useEffect(() => {
        var userCountry;
        var userTimeZone;
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        userCountry = mapTimezoneToCountry[userTimeZone];
        if (userCountry !== undefined) {
            if (
                userCountry.includes("Cote d'Ivoire") ||
                userCountry.includes("Congo (Dem. Rep.)") ||
                userCountry.includes("Congo (Rep.)") ||
                userCountry.includes("Madagascar") ||
                userCountry.includes("Cameroon") ||
                userCountry === "Niger" ||
                userCountry.includes("Burkina Faso") ||
                userCountry.includes("Mali") ||
                userCountry.includes("Senegal") ||
                userCountry.includes("Chad") ||
                userCountry.includes("Guinea") ||
                userCountry.includes("Rwanda") ||
                userCountry.includes("Burundi") ||
                userCountry.includes("Benin") ||
                userCountry.includes("Togo") ||
                userCountry.includes("Central African Rep.") ||
                userCountry.includes("Gabon") ||
                userCountry.includes("Djibouti") ||
                userCountry.includes("Equatorial Guinea") ||
                userCountry.includes("Comoros") ||
                userCountry.includes("Seychelles")
            ) {
                setLang("fr");
            }
        }
    }, []);

    const isLangSet = localStorage.getItem("isLangSet");
    useEffect(() => {
        setLang(isLangSet !== null ? isLangSet : "en");
    }, []);

    useEffect(() => {
        var myNav = document.getElementById("nav");
        window.onscroll = function () {
            "use strict";
            if (document.body.scrollTop >= 70 || document.documentElement.scrollTop >= 70) {
                myNav.classList.add("scBg");
                setIniColor("#667085");
            } else {
                myNav.classList.remove("scBg");
                setIniColor("#fff");
            }
        };
    }, []);

    const AccordionItem = ({ header, ...rest }) => (
        <NewItem
            {...rest}
            header={
                <Flex align={["flex-start", "center"]} w="100%" justify="space-between">
                    <Text
                        mr="6"
                        className="font"
                        fontSize={["20px", "24px"]}
                        lineHeight={["28px", "38px"]}
                        fontWeight="500"
                        textAlign="left"
                        color="#071827"
                    >
                        {header}
                    </Text>
                    <Image src={plus} w={["20px", "26px"]} h={["20px", "26px"]} className="btnIcon1" />
                    <Image src={minus} w={["20px", "26px"]} h={["20px", "26px"]} className="btnIcon2" />
                </Flex>
            }
            buttonProps={{
                className: ({ isEnter }) => `${isEnter ? "itemBtnExpanded" : "notItemBtnExpanded"}`,
            }}
        />
    );

    const faqs = [
        {
            header: lang === "fr" ? "Qu'est-ce que le portefeuille Zine?" : "What is Zine Wallet?",
            content:
                lang === "fr"
                    ? "Zine Wallet est une application mobile conviviale qui facilite le trading transparent de crypto-monnaies, permettant aux utilisateurs d'acheter, de vendre et de détenir divers actifs numériques."
                    : "Zine Wallet is a user-friendly mobile application that facilitates seamless trading of cryptocurrencies, enabling users to buy, sell, and hold various digital assets.",
        },
        {
            header:
                lang === "fr"
                    ? "Quelles crypto-monnaies puis-je échanger sur Zine Wallet?"
                    : "What cryptocurrencies can I trade on Zine Wallet?",
            content:
                lang === "fr"
                    ? "Avec Zine Wallet, vous pouvez échanger une large gamme de crypto-monnaies, notamment Bitcoin, Ethereum et de nombreux autres altcoins."
                    : "With Zine Wallet, you can trade a wide range of cryptocurrencies, including Bitcoin, Ethereum, and many other altcoins.",
        },
        {
            header:
                lang === "fr"
                    ? "Dans quelle mesure le portefeuille Zine est-il sécurisé?"
                    : "How secure is Zine Wallet?",
            content:
                lang === "fr"
                    ? "La sécurité est notre priorité absolue chez Zine Wallet. Nous utilisons des protocoles de cryptage et de sécurité avancés pour garantir la sécurité de vos actifs et de vos informations personnelles. De plus, nous mettons continuellement à jour nos mesures de sécurité pour garder une longueur d’avance sur les menaces émergentes."
                    : "Security is our top priority at Zine Wallet. We employ advanced encryption and security protocols to ensure the safety of your assets and personal information. Additionally, we continuously update our security measures to stay ahead of emerging threats.",
        },
        {
            header:
                lang === "fr"
                    ? "Puis-je définir des achats automatiques sur Zine Wallet?"
                    : "Can I set automatic purchases on Zine Wallet?",
            content:
                lang === "fr"
                    ? "Oui, Zine Wallet vous permet de configurer des achats automatiques à une heure déterminée par vous. Cette fonctionnalité vous permet d’investir sans effort dans des devises stables, même si votre emploi du temps est chargé."
                    : "Yes, Zine Wallet allows you to set up automatic purchases at a time determined by you. This feature enables you to invest in stable currencies effortlessly, even if you have a busy schedule.",
        },
        {
            header:
                lang === "fr"
                    ? "Puis-je envoyer de l'argent à d'autres utilisateurs de Zine Wallet?"
                    : "Can I send money to fellow users on Zine Wallet?",
            content:
                lang === "fr"
                    ? "Oui, Zine Wallet permet des transferts d'argent rapides et sécurisés entre utilisateurs sans frais de transaction. Que vous partagiez une facture ou envoyiez des fonds à un ami, notre plateforme garantit une expérience transparente et sans tracas."
                    : "Yes, Zine Wallet enables fast and secure money transfers between users with zero transaction fees. Whether you're splitting a bill or sending funds to a friend, our platform ensures a seamless and hassle-free experience.",
        },
        {
            header:
                lang === "fr"
                    ? "Comment puis-je démarrer avec Zine Wallet?"
                    : "How can I get started with Zine Wallet?",
            content:
                lang === "fr"
                    ? "Démarrer avec Zine Wallet est simple. Téléchargez simplement notre application mobile depuis l'App Store ou le Google Play Store, créez un compte et commencez à explorer le monde du trading de cryptomonnaies et du transfert d'argent rapide."
                    : "Getting started with Zine Wallet is easy. Simply download our mobile application from the App Store or Google Play Store, create an account, and start exploring the world of cryptocurrency trading and fast money transfer.",
        },
        {
            header:
                lang === "fr"
                    ? "Le support client est-il disponible sur Zine Wallet?"
                    : "Is customer support available on Zine Wallet?",
            content:
                lang === "fr"
                    ? "Oui, notre équipe de support client dédiée est disponible pour vous aider avec toutes vos questions ou préoccupations. Vous pouvez nous contacter via la page d'assistance ou sur votre mobile, et nous serons heureux de vous aider."
                    : "Yes, our dedicated customer support team is available to assist you with any questions or concerns you may have. You can reach out to us through  the support page or on the mobile, and we'll be happy to help.",
        },
        {
            header:
                lang === "fr"
                    ? "Dans combien de pays Zine Wallet est-il présent?"
                    : "How many countries does Zine Wallet operate in?",
            content:
                lang === "fr"
                    ? "Zine Wallet est disponible pour les utilisateurs du Nigeria et de Côte d'Ivoire, permettant aux individus de différentes régions d'accéder à notre plateforme et de profiter de ses fonctionnalités de trading de crypto-monnaie et de gestion financière."
                    : "Zine Wallet is available to users in Nigeria and Cote D’Ivoire allowing individuals from different regions to access our platform and take advantage of its features for cryptocurrency trading and financial management.",
        },
    ];

    const ivSection = (title, sub, img, list, lr) => {
        return (
            <Flex w="100%" justify="space-between" direction={["column", lr ? "row-reverse" : "row"]}>
                <Flex w={["100%", "41%"]} direction="column">
                    <Text fontSize={["20px", "32px"]} lineHeight={["28px", "48px"]} fontWeight="700">
                        {title}
                    </Text>
                    <Text lineHeight={["22.4px", "28px"]} fontSize={["16px", "20px"]} mt="3" color="#667085">
                        {sub}
                    </Text>
                    <Flex w="100%" direction="column" mt="10" pb={["6", "8"]}>
                        {list.map((item, index) => (
                            <Flex align="center" key={index} w="100%" mb="4">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14.78 7.7L9.11 13.37C8.97 13.51 8.78 13.59 8.58 13.59C8.38 13.59 8.19 13.51 8.05 13.37L5.22 10.54C4.93 10.25 4.93 9.77 5.22 9.48C5.51 9.19 5.99 9.19 6.28 9.48L8.58 11.78L13.72 6.64C14.01 6.35 14.49 6.35 14.78 6.64C15.07 6.93 15.07 7.4 14.78 7.7Z"
                                        fill="#6421F2"
                                    />
                                </svg>
                                <Flex flex="1" ml="3" fontSize={["16px", "20px"]} color="#212B36">
                                    {item}
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
                <Flex
                    w={["100%", "47%"]}
                    h={["400px", "auto"]}
                    backgroundImage={img}
                    borderRadius="16px"
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center center"
                ></Flex>
            </Flex>
        );
    };

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" id="nav" position="fixed" top="0" left="0" zIndex="1" direction="column">
                <NavBar iniColor={iniColor} setLang={setLang} lang={lang} />
            </Flex>

            <Flex
                w="100%"
                justify="center"
                bgColor="#6421F2"
                backgroundImage={lang === "fr" ? bg2 : bg1}
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                backgroundPosition="center center"
            >
                <Flex className="container" px={["5", "3%"]}>
                    <Flex
                        minH={["70vh", "100vh"]}
                        w="100%"
                        direction="column"
                        pt={["100px", "160px"]}
                        align="center"
                        color="#fff"
                    >
                        <Text
                            data-aos="fade-down"
                            data-aos-duration="3000"
                            fontWeight="700"
                            fontSize={["40px", "64px"]}
                            lineHeight={["49.2px", "72px"]}
                            textAlign="center"
                            w={["100%", "840px"]}
                        >
                            {lang === "fr"
                                ? "Zine - La seule application financière dont vous aurez besoin"
                                : "Zine - The only Financial App you will ever need"}
                        </Text>
                        <Flex mt={["50px", "80px"]} mb={["80px", "80px"]} align="center">
                            <Image
                                src={gp}
                                cursor="pointer"
                                mr="4"
                                h={["40px", "52px"]}
                                onClick={() => window.open("https://play.google.com/store/apps/", "_BLANK")}
                            />
                            <Image
                                src={ap}
                                cursor="pointer"
                                h={["40px", "52px"]}
                                onClick={() => window.open("https://apps.apple.com/ng/app/", "_BLANK")}
                            />
                        </Flex>
                        <Flex data-aos="fade-up" data-aos-duration="3000">
                            <Image src={lang === "fr" ? hs1french : hs1} w={["256px", "404px"]} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" justify="center" bg="#FCFAFF">
                <Flex className="container" px={["0", "5%"]}>
                    <Flex
                        w="100%"
                        py={["80px", "120px"]}
                        justify="space-between"
                        direction={["column", "row"]}
                        align="center"
                    >
                        <Flex mb={["12", "0"]} w={["100%", "45%"]} direction="column" align="center">
                            <Text
                                data-aos="fade-down"
                                color="#101828"
                                fontSize={["32px", "48px"]}
                                lineHeight={["44.8px", "71px"]}
                                fontWeight="700"
                                w={["100%", "342px"]}
                                px={["20%", "0"]}
                            >
                                {lang === "fr"
                                    ? "Les prix actuels des crypto-monnaies"
                                    : "Today’s Cryptocurrency Prices"}
                            </Text>
                            <Flex w="100%" mt="12" data-aos="fade-up" px={["1", "0"]}>
                                <Flex w="100%" h="300px">
                                    <TradingViewWidget2 coin={coin} coinIni={coinIni} />
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            w={["90%", "45%"]}
                            mx={["auto", "0"]}
                            data-aos="fade-up"
                            bg="#fff"
                            boxShadow="0px 14.87px 29.73px -4.96px #919EAB1F"
                            borderRadius="8px"
                            pt="2"
                            pb="3"
                            direction="column"
                        >
                            {loading ? (
                                <Flex w="100%" py="100px" justify="center" align="center">
                                    <Spinner w="60px" h="60px" color="#6421F2" emptyColor="lightgrey" />
                                </Flex>
                            ) : (
                                <Flex w="100%" direction="column">
                                    {cArr
                                        ?.sort((a, b) => b.trading.selling_for - a.trading.selling_for)
                                        ?.map((item, index) => (
                                            <Flex
                                                key={index}
                                                align="center"
                                                py="6"
                                                borderBottom={index < 4 && "1px solid #EAECF0"}
                                                fontSize={["14px", "17px"]}
                                                fontWeight="500"
                                                color="#212B36"
                                                onClick={() => {
                                                    setCoin(item?.base);
                                                    setCoinIni("true");
                                                }}
                                                cursor="pointer"
                                                bg={item?.base === coin ? "rgb(245,245,245)" : "transparent"}
                                                _hover={{ bg: "rgb(245,245,245)" }}
                                                transition="300ms ease-in-out"
                                            >
                                                <Flex align="center" w="40%" px={["5", "6"]}>
                                                    <Image
                                                        w={["18px", "32px"]}
                                                        h={["18px", "32px"]}
                                                        mr="4"
                                                        src={
                                                            item?.base === "BTC"
                                                                ? btc
                                                                : item?.base === "ETH"
                                                                ? eth
                                                                : item?.base === "BNB"
                                                                ? bnb
                                                                : item?.base === "SOL"
                                                                ? sol
                                                                : usd
                                                        }
                                                    />
                                                    <Text>
                                                        {item?.base === "BTC"
                                                            ? "Bitcoin"
                                                            : item?.base === "ETH"
                                                            ? "Ethereum"
                                                            : item?.base === "BNB"
                                                            ? "BNB"
                                                            : item?.base === "SOL"
                                                            ? "Solana"
                                                            : "Tether"}
                                                    </Text>
                                                </Flex>
                                                <Flex align="center" w="40%" px={["5", "6"]}>
                                                    ${parseFloat(item?.trading?.selling_for)?.toLocaleString()}
                                                </Flex>
                                                <Flex
                                                    align="center"
                                                    w="20%"
                                                    justify="flex-end"
                                                    px={["5", "6"]}
                                                    fontWeight="700"
                                                    color={
                                                        item?.performance?.formatted?.charAt(0) === "-"
                                                            ? "#F04438"
                                                            : "#12B76A"
                                                    }
                                                    fontSize="14px"
                                                >
                                                    {item?.performance?.formatted}
                                                </Flex>
                                            </Flex>
                                        ))}
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            {lang === "fr" ? (
                <Flex w="100%" justify="center" bg="#fff">
                    <Flex className="container" px={["5", "8%"]}>
                        <Flex w="100%" py={["60px", "120px"]} direction="column">
                            {ivSection(
                                "Recharges instantanées de temps d'antenne",
                                "Avec Zine Wallet, recharger votre temps d'antenne est aussi simple que quelques clics. Dites adieu aux recharges manuelles dans les centres proches de chez vous. Profitez de charges instantanées de vos crédits mobiles à tout moment et en tout lieu.",
                                her1,
                                ["Charges instantanées de crédits mobiles", "Fini les recharges manuelles"]
                            )}
                            <Flex h={["70px", "150px"]}></Flex>
                            {ivSection(
                                "Paiement pratique de l'électricité",
                                "Ne vous inquiétez plus jamais des factures d'électricité en souffrance. Le portefeuille Zine vous permet de régler votre paiement d'électricité sans effort, garantissant une alimentation électrique ininterrompue pour votre maison ou votre entreprise.",
                                her2,
                                [
                                    "Réglez facilement le paiement de l'électricité",
                                    "Assurer une alimentation électrique constante",
                                ],
                                true
                            )}
                            <Flex h={["70px", "150px"]}></Flex>
                            {ivSection(
                                "Paiement transparent des factures d'eau",
                                "Dites adieu aux frais de retard et aux déconnexions avec Zine Wallet. Maintenez la fluidité de votre eau en payant vos factures en quelques clics sur votre smartphone. C'est le moyen le plus simple de gérer vos dépenses en eau sans tracas.",
                                her3,
                                ["Payez en quelques étapes seulement", "Hassle-free!"]
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            ) : (
                <Flex w="100%" justify="center" bg="#fff">
                    <Flex className="container" px={["5", "3%"]}>
                        <Flex w="100%" py={["60px", "120px"]} direction="column">
                            <Flex w="100%" justify="space-between" direction={["column", "row"]}>
                                <Flex
                                    mb={["6", "0"]}
                                    data-aos="fade-up"
                                    w={["100%", "42%"]}
                                    direction="column"
                                    bg="#F5F1FE"
                                    border="1px solid #E0D4FC"
                                    px={["5", "12"]}
                                    py="10"
                                    borderRadius="16px"
                                >
                                    <Text lineHeight="48px" fontWeight="700" fontSize={["20px", "24px"]}>
                                        {lang === "fr" ? "Investissez dans un actif stable" : "Invest in Stable Asset"}
                                    </Text>
                                    <Text
                                        mt="1"
                                        fontSize={["16px", "20px"]}
                                        color="#667085"
                                        pr={["0", "7%"]}
                                        lineHeight={["22.4px", "28px"]}
                                    >
                                        {lang === "fr"
                                            ? "Dans un environnement économique en constante évolution, le portefeuille Zine devient votre protection contre les incertitudes. Notre fonctionnalité révolutionnaire vous permet d'investir dans des actifs solides comme des pièces stables, ce qui protège la valeur de votre investissement dans le temps."
                                            : "In an ever-changing economic environment, Zine wallet becomes your safeguard against the uncertainties. Our revolutionary feature allows you to invest in solid assets like stable coins, which protects the value of your investment over time."}
                                    </Text>
                                </Flex>

                                <Flex
                                    data-aos="fade-down"
                                    w={["100%", "55%"]}
                                    direction="column"
                                    bg="#FCF4F2"
                                    border="1px solid #F4D4CD"
                                    px={["5", "12"]}
                                    pt="10"
                                    pb="6"
                                    borderRadius="16px"
                                >
                                    <Flex w="100%" justify="center">
                                        <Text
                                            w={["233.25px", "auto"]}
                                            mb={["5", "0"]}
                                            fontSize={["20px", "24px"]}
                                            fontWeight="700"
                                            lineHeight="33.6px"
                                        >
                                            {lang === "fr"
                                                ? "Découvrez comment vos crypto-monnaies préférées ont réalisé des heures supplémentaires."
                                                : "See how your favorite cryptocurrencies have performed overtime."}
                                        </Text>
                                    </Flex>
                                    <Flex w="100%" mt="3">
                                        <Flex w="100%" h="400px">
                                            <TradingViewWidget />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex
                                mt={["6", "3%"]}
                                bg="#EFF5FA"
                                border="1px solid #CCDFEF"
                                px={["0", "6"]}
                                pt={["10", "6"]}
                                borderRadius="16px"
                                justify="space-between"
                                align="center"
                                direction={["column-reverse", "row"]}
                            >
                                <Flex mt="auto" w={["100%", "45%"]} data-aos="fade-up">
                                    <Image src={phone1Mob} w="100%" display={["flex", "none"]} />
                                    <Image src={phone1} w="100%" display={["none", "flex"]} />
                                </Flex>
                                <Flex
                                    px={["8", "0"]}
                                    w={["100%", "50%"]}
                                    mb={["-270px", "0"]}
                                    direction="column"
                                    pb={["0", "3"]}
                                >
                                    <Text
                                        data-aos="fade-down"
                                        fontSize={["20px", "24px"]}
                                        lineHeight={["28px", "48px"]}
                                        fontWeight="700"
                                    >
                                        {lang === "fr"
                                            ? "Définir l'achat automatique à une heure définie par “vous”"
                                            : "Set Automatic Purchase At A Time Set By “You”"}
                                    </Text>
                                    <Text
                                        data-aos="fade-up"
                                        color="#667085"
                                        mt={["3", "1"]}
                                        fontSize={["16px", "20px"]}
                                        pr="5%"
                                        lineHeight={["22.4px", "28px"]}
                                    >
                                        {lang === "fr"
                                            ? "Si vous êtes comme moi, et que vous oubliez parfois de prendre votre petit-déjeuner, ne vous inquiétez pas pour votre investissement car nous avons ce qu'il vous faut. Votre pièce stable est automatiquement achetée à l'heure que vous avez fixée à l'aide de notre fonction d'achat périodique."
                                            : "In case you are like me, who forget to eat breakfast sometimes, then do not worry about your investment because we have got you covered. Your stable coin is automatically purchased at the time set by you using our periodic buy feature."}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            )}
            <Flex w="100%" justify="center" bg="#fff">
                <Flex className="container" px={["5", "3%"]}>
                    <Flex w="100%" py={["20px", "100px"]} justify="space-between" direction={["column", "row"]}>
                        <Flex
                            mb={["4", "0"]}
                            w={["100%", "52%"]}
                            data-aos="fade-up"
                            backgroundImage={sec1}
                            backgroundSize="cover"
                            backgroundRepeat="no-repeat"
                            backgroundPosition="center center"
                            border="1px solid #E0D4FC"
                            borderRadius="16px"
                        >
                            <Flex
                                w="100%"
                                h="100%"
                                py="12"
                                px={["6", "12"]}
                                borderRadius="16px"
                                justify="center"
                                direction="column"
                                color="#fff"
                            >
                                <Flex ml={["0", "6"]}>
                                    <Image src={phone2} w={["206px", "225px"]} />
                                </Flex>
                                <Flex w="100%" direction="column" mt={["6", "12"]}>
                                    <Text lineHeight="48px" fontWeight="700" fontSize={["20px", "24px"]}>
                                        {lang === "fr" ? "Application conviviale" : "User friendly Application"}
                                    </Text>
                                    <Text mt="1" fontSize={["16px", "20px"]} pr="5%" lineHeight={["22.4px", "28px"]}>
                                        {lang === "fr"
                                            ? "Découvrez la puissance de l'application mobile conviviale Zine, conçue pour améliorer votre parcours de trading de crypto-monnaie. Le portefeuille Zine garantit une expérience transparente, vous permettant d'acheter, de vendre et de conserver sans effort vos crypto-monnaies préférées à portée de main."
                                            : "Experience the power of Zine user-friendly mobile application, designed to elevate your cryptocurrency trading journey. Zine wallet ensures a seamless experience, enabling you to effortlessly buy, sell and hold your favorite cryptocurrencies right at your fingertips."}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex w={["100%", "46%"]} direction="column">
                            <Flex
                                w="100%"
                                data-aos="fade-down"
                                backgroundImage={sec2}
                                backgroundSize="cover"
                                backgroundRepeat="no-repeat"
                                backgroundPosition="center center"
                                borderRadius="16px"
                            >
                                <Flex
                                    w="100%"
                                    h="100%"
                                    py={["40px", "60px"]}
                                    px={["6", "8"]}
                                    borderRadius="16px"
                                    direction="column"
                                    color="#fff"
                                >
                                    <Text lineHeight="33.6px" fontWeight="700" fontSize={["20px", "24px"]}>
                                        {lang === "fr" ? "Tradez des crypto-monnaies" : "Trade Cryptocurrencies"}
                                    </Text>
                                    <Text mt="1" fontSize="16px" pr={["0", "10%"]} lineHeight="22px" fontWeight="400">
                                        {lang === "fr"
                                            ? "Élargissez votre portefeuille au-delà des célèbres Bitcoin et Ethereum, car nous offrons l'accès à un large éventail de crypto-monnaies."
                                            : "Expand your portfolio beyond the well-known Bitcoin and Ethereum, as we offer access to a wide array of cryptocurrencies."}
                                    </Text>
                                    <Flex w="100%" mt="12">
                                        <Image src={sec4} w={["267px", "250px"]} />
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex
                                mt={["4", "8"]}
                                data-aos="fade-up"
                                w="100%"
                                backgroundImage={sec3}
                                backgroundSize="cover"
                                backgroundRepeat="no-repeat"
                                backgroundPosition="center center"
                                borderRadius="16px"
                                color="#fff"
                            >
                                <Flex
                                    w="100%"
                                    h="100%"
                                    py={["30px", "80px"]}
                                    px={["6", "8"]}
                                    borderRadius="16px"
                                    justify="space-between"
                                    align="flex-start"
                                    direction={["column", "row"]}
                                >
                                    <Flex w={["100%", "60%"]} direction="column">
                                        <Text
                                            lineHeight="33.6px"
                                            fontWeight="700"
                                            fontSize={["20px", "24px"]}
                                            pr={["0", "10%"]}
                                        >
                                            {lang === "fr"
                                                ? "Vendez votre crypto et obtenez du naira en quelques secondes"
                                                : "Sell your Crypto and Get Naira in Seconds"}
                                        </Text>
                                        <Text
                                            mt="1"
                                            fontSize="16px"
                                            pr={["0", "10%"]}
                                            lineHeight="22px"
                                            fontWeight="400"
                                        >
                                            {lang === "fr"
                                                ? "Convertissez facilement vos avoirs cryptographiques en naira ou en d’autres actifs. Vendez vos actifs cryptographiques et obtenez immédiatement votre équivalence fiduciaire dans votre portefeuille"
                                                : "Convert your crypto holdings into naira or other assets easily. Sell your crypto assets and get your fiat equivalence in your wallet immediately"}
                                        </Text>
                                    </Flex>
                                    <Flex mt={["8", "0"]} w={["141px", "40%"]} justify="center">
                                        <Image src={sec5} w={["100%", "90%"]} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" justify="center" bg="#fff">
                <Flex className="container" px={["5", "3%"]}>
                    <Flex w="100%" py="40px">
                        <Flex
                            w="100%"
                            bg="#EFF5FA"
                            border="1px solid #CCDFEF"
                            p={["5", "6"]}
                            borderRadius="16px"
                            justify="space-between"
                            align="center"
                            direction={["column", "row"]}
                        >
                            <Flex w={["100%", "398px"]} data-aos="fade-up">
                                <Image src={hs2} w="100%" />
                            </Flex>
                            <Flex direction="column" mt={["8", "0"]} flex="1" ml={["0", "7%"]}>
                                <Flex align="center" data-aos="fade-down">
                                    <Image src={flash} w={["37px", "59px"]} />
                                    <Flex
                                        ml="3"
                                        bg="#238BE1"
                                        borderRadius="3.7px"
                                        px="6"
                                        py="2"
                                        fontSize={["16px", "26.52px"]}
                                        color="#fff"
                                        fontWeight="700"
                                    >
                                        {lang === "fr" ? "Zéro Frais!" : "Zero Fees!"}
                                    </Flex>
                                </Flex>
                                <Flex data-aos="fade-up" mt="8" w="100%" pb={["3", "0"]} direction="column">
                                    <Text lineHeight="33.6px" fontWeight="700" fontSize={["20px", "24px"]}>
                                        {lang === "fr"
                                            ? "Frais de transaction rapides et nuls parmi les utilisateurs de Zine"
                                            : "Fast and Zero Transaction Fees amongst Zine Users"}{" "}
                                    </Text>
                                    <Text
                                        mt="4"
                                        fontSize={["16px", "20px"]}
                                        color="#667085"
                                        pr={["0", "7%"]}
                                        lineHeight={["22.4px", "28px"]}
                                    >
                                        {lang === "fr"
                                            ? "Fatigué des frais de transaction et des retards du réseau ? Zine Wallet élimine ces maux de tête en vous permettant d'envoyer de l'argent à d'autres utilisateurs de zine rapidement et sans frais de transaction."
                                            : "Tired of transaction fees and network delays? Zine Wallet eliminates these headaches by allowing you to send money to other zine users swiftly and with zero transaction fees."}
                                         
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" justify="center" bg="#F5F1FE" id="aboutPage">
                <Flex className="container" px={["5", "3%"]}>
                    <Flex w="100%" py={["8", "120px"]} direction={["column", "row"]}>
                        <Flex
                            w={["100%", "42%"]}
                            h={["533.62px", "auto"]}
                            borderRadius={["16px 16px 0px 0px", "16px 0px 0px 16px"]}
                            backgroundImage={hs3}
                            backgroundSize="cover"
                            backgroundRepeat="no-repeat"
                            backgroundPosition="center center"
                        ></Flex>

                        <Flex
                            flex="1"
                            bg="#895EED"
                            borderRadius={["0px 0px 16px 16px", "0px 16px 16px 0px"]}
                            px={["5", "12"]}
                            py={["8", "100px"]}
                            direction="column"
                            color="#fff"
                        >
                            <Text
                                data-aos="fade-down"
                                fontSize={["20px", "32px"]}
                                lineHeight={["28px", "48px"]}
                                fontWeight="bold"
                                pr={["0", "12%"]}
                            >
                                {lang === "fr"
                                    ? "Promouvoir l’autonomisation économique en Afrique grâce à l’adoption de la cryptographie."
                                    : "Promoting Economic Empowerment in Africa Through Crypto Adoption."}
                            </Text>
                            <Text data-aos="fade-up" fontSize={["16px", "20px"]} lineHeight={["22.4px", "28px"]} mt="7">
                                {lang === "fr"
                                    ? "Chez Zine, notre mission est de permettre aux particuliers et aux entreprises en Afrique et au-delà d'exploiter le potentiel des crypto-monnaies grâce à une plateforme sécurisée, digne de confiance et centrée sur l'utilisateur. En renforçant la confiance locale, en proposant une éducation complète et en adaptant nos services pour répondre aux besoins uniques du marché africain, nous visons à simplifier les transactions de crypto-monnaie et à favoriser une adoption généralisée."
                                    : "At Zine, our mission is to empower individuals and businesses in Africa and beyond to embrace the potential of cryptocurrencies through a secure, trustworthy, and user-centric platform. By building local trust, providing comprehensive education, and tailoring our services to meet the unique needs of the African market, we aim to simplify cryptocurrency transactions and foster widespread adoption."}
                                 
                                <Text mt="6">
                                    {lang === "fr"
                                        ? "Notre engagement en faveur de la convivialité, de la sécurité et de la performance financière nous pousse à créer des solutions innovantes qui permettent un accès, une gestion et une utilisation transparentes des actifs numériques, façonnant ainsi l'avenir de l'inclusion financière et de l'autonomisation à l'ère numérique."
                                        : "Our commitment to user-friendliness, security, and financial performance drives us to create innovative solutions that enable seamless access, management, and utilization of digital assets, ultimately shaping the future of financial inclusion and empowerment in the digital age."}
                                </Text>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" justify="center" bg="#F5F1FE" id="contactPage">
                <Flex className="container" px={["5", "3%"]}>
                    <Flex w="100%" py={["60px", "96px"]} justify="space-between" direction={["column", "row"]}>
                        <Flex w={["100%", "432px"]}>
                            <Text
                                data-aos="fade-down"
                                fontWeight="700"
                                fontSize={["32px", "48px"]}
                                lineHeight={["44.8px", "64px"]}
                            >
                                <Text color="#4E0DD9" as="span">
                                    {lang === "fr" ? "Prenez contact avec nous!" : "Get in touch with us!"}
                                </Text>{" "}
                                {lang === "fr"
                                    ? "Parlez avec un représentant de Zine"
                                    : " Talk with a Zine representative"}
                            </Text>
                        </Flex>
                        <Flex flex="1" mt={["12", "0"]} ml={["0", "10%"]} direction="column">
                            <form onSubmit={(e) => contactFunc(e)}>
                                <InputHolder
                                    type="text"
                                    placeholder={lang === "fr" ? "Nom de famille" : "First Name"}
                                    required
                                    inpRef={ref1}
                                />
                                <InputHolder
                                    type="text"
                                    placeholder={lang === "fr" ? "Prénom" : "Last Name"}
                                    required
                                    inpRef={ref2}
                                />
                                <InputHolder
                                    type="tel"
                                    placeholder={lang === "fr" ? "Numéro de téléphone" : "Phone Number"}
                                    required
                                    inpRef={ref3}
                                />
                                <InputHolder
                                    type="email"
                                    placeholder={lang === "fr" ? "Adresse e-mail" : "Email Address"}
                                    required
                                    inpRef={ref4}
                                />
                                <InputHolder
                                    type="textarea"
                                    placeholder={lang === "fr" ? "Écrivez votre message" : "Write your message"}
                                    required
                                    inpRef={ref5}
                                />
                                <Flex
                                    as="button"
                                    bg="#6421F2"
                                    color="#fff"
                                    padding="10px 18px"
                                    borderRadius="8px"
                                    fontSize="16px"
                                    fontWeight="500"
                                    cursor="pointer"
                                    justify="center"
                                    align="center"
                                    w="100%"
                                >
                                    {loading2 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Submit"}
                                </Flex>
                            </form>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" justify="center" bg={["#F6F0FF", "#fff"]}>
                <Flex className="container" px={["5", "3%"]}>
                    <Flex w="100%" pt={["34px", "64px"]} pb="64px">
                        <Flex
                            w="100%"
                            bg="#F6F0FF"
                            borderRadius="16px"
                            px={["2", "12%"]}
                            py={["0", "12"]}
                            justify="space-between"
                            align="center"
                            direction={["column", "row"]}
                        >
                            <Flex w={["100%", "318px"]} data-aos="fade-down">
                                <Image src={hs4} w={["100%", "318px"]} />
                            </Flex>
                            <Flex mt={["12", "0"]} flex="1" direction="column" ml={["0", "8%"]} data-aos="fade-up">
                                <Text color="#360995" fontSize="32px" fontWeight="700" lineHeight={["44.8px", "38px"]}>
                                    {lang === "fr"
                                        ? "Téléchargez l'application Zine dès aujourd'hui !"
                                        : "Download the Zine app today!"}
                                </Text>
                                <Text color="#6421F2" lineHeight="30px" fontSize="20px" mt="3">
                                    {lang === "fr"
                                        ? "Téléchargez l'application Zine dès aujourd'hui !"
                                        : "Our user friendly application gives you the "}
                                </Text>
                                <Flex mt="6" align="center">
                                    <Image
                                        src={gp}
                                        cursor="pointer"
                                        mr="4"
                                        h={["45px", "52px"]}
                                        onClick={() => window.open("https://play.google.com/store/apps/", "_BLANK")}
                                    />
                                    <Image
                                        src={ap}
                                        cursor="pointer"
                                        h={["45px", "52px"]}
                                        onClick={() => window.open("https://apps.apple.com/ng/app/", "_BLANK")}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" justify="center" bg="#F9FAFB">
                <Flex className="container" px={["5", "3%"]}>
                    <Flex w="100%" py="96px" justify="space-between" direction={["column", "row"]}>
                        <Flex w={["100%", "348px"]}>
                            <Text
                                data-aos="fade-down"
                                fontWeight="700"
                                fontSize={["32px", "48px"]}
                                lineHeight={["48px", "64px"]}
                            >
                                {lang === "fr" ? "Questions" : "Frequently Asked"}{" "}
                                <Text color="#4E0DD9">{lang === "fr" ? "Fréquemment Posées" : "Questions"}</Text>
                            </Text>
                        </Flex>
                        <Flex mt={["6", "0"]} data-aos="fade-up" flex="1" ml={["0", "5%"]} direction="column">
                            <Accordion transition transitionTimeout={300}>
                                {faqs.map(({ header, content }, i) => (
                                    <AccordionItem header={header} key={i} initialEntered={i === 0 ? true : false}>
                                        {content}
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
