import { useEffect, useState } from "react";

import amlpolicy from "../assets/markdown/aml-policy.md";
import MarkdownRenderer from "../components/markdown-renderer";

const fetchContent = async () => {
    const res = await fetch(amlpolicy);
    return await res.text();
};

const AMLPolicy = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetchContent().then((res) => setContent(res));
    }, []);

    return <MarkdownRenderer content={content} />;
};

export default AMLPolicy;
