import { useEffect, useState } from "react";

import terms from "../assets/markdown/privacy-policy.md";
import MarkdownRenderer from "../components/markdown-renderer";

const fetchContent = async () => {
    const res = await fetch(terms);
    return await res.text();
};

const Privacy = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetchContent().then((res) => setContent(res));
    }, []);

    return <MarkdownRenderer content={content} />;
};

export default Privacy;
