import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../styles/global.css";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import Landing from "./landing";

import Footer from "../components/footer";
import ScrollToTop from "../components/sc";
import Privacy from "./privacy-policy";
import AMLPolicy from "./aml-policy";
import TermsAndCondition from "./terms-and-condition";
import { useEffect, useState } from "react";

function App() {
    const [lang, setLang] = useState("");

    const isLangSet = localStorage.getItem("isLangSet");

    useEffect(() => {
        setLang(isLangSet !== null ? isLangSet : "en");
    }, []);

    return (
        <ChakraProvider>
            <Flex direction="column" fontSize="16px" color="#101828">
                <Router>
                    <ScrollToTop>
                        <Flex w="100%" direction="column">
                            <Switch>
                                <Route path="/terms-and-conditions" component={TermsAndCondition} />
                                <Route path="/privacy-policy" component={Privacy} />
                                <Route path="/aml-policy" component={AMLPolicy} />
                                <Route exact path="/" component={Landing} />
                            </Switch>

                            <Flex w="100%">
                                <Footer lang={lang} />
                            </Flex>
                        </Flex>
                    </ScrollToTop>
                </Router>
            </Flex>
        </ChakraProvider>
    );
}

export default App;
