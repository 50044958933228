import { useEffect, useState } from "react";

import termsAndCondition from "../assets/markdown/terms-and-conditions.md";
import MarkdownRenderer from "../components/markdown-renderer";

const fetchContent = async () => {
    const res = await fetch(termsAndCondition);
    return await res.text();
};

const TermsAndCondition = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetchContent().then((res) => setContent(res));
    }, []);

    return <MarkdownRenderer content={content} />;
};

export default TermsAndCondition;
